<template>
    <v-dialog width="580" v-model="show">
        <v-card>
            <v-card-title class="dialog-header-text">
                Benefits
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click.stop="show=false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-5">
                <div style="font-size: 1rem;font-family: Montserrat;  color: black"
                     class="mt-7 mb-1">
                    Instant decision term coverage up to 30 years and <sup>$</sup>1M.
                </div>
                <v-row class="pa-4 pl-0">
                    <v-col class="col-1 pr-0">
                        <img :src="require('../assets/QLT-Benefit-Icon_1_21x21.svg')" class="text-icon" />
                    </v-col>
                    <v-col class="col-10 pl-1 ml-md-n2 benefit-item-text">
                        Option to convert to lifetime.
                    </v-col>
                </v-row>
                <v-row class="pa-4 pl-0 mt-n8">
                    <v-col class="col-1 pr-0">
                        <img :src="require('../assets/QLT-Benefit-Icon_2_22x19.svg')" class="text-icon" />
                    </v-col>
                    <v-col class="col-10 pl-1 ml-md-n2 benefit-item-text">
                        Charitable giving feature.
                    </v-col>
                </v-row>
                <v-row class="pa-4 pl-0 mt-n8">
                    <v-col class="col-1 pr-0">
                        <img :src="require('../assets/08_20-09-09_Life-Insurance-Guide_Products_Final-Expense-Icon.svg')" class="text-icon" />
                    </v-col>
                    <v-col class="col-10 pl-1 ml-md-n2 benefit-item-text">
                        Optional accidental death protection.
                    </v-col>
                </v-row>
                <v-row class="pa-4 pl-0 mt-n8">
                    <v-col class="col-1 pr-0"></v-col>
                    <v-col class="col-10 pl-1 ml-md-n2 benefit-item-text">
                        <i>And more!</i>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SBLI_Benefit_Dialogue",
    props: ['visible'],
    computed: {
        show: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                if (!value) {
                    this.$emit('input', value);
                }
            }
        }
    }
}
</script>

<style scoped>
.benefit-item-text {
    font-size: 1rem; font-family: Montserrat, Light; font-weight: 300; color: black;
}

.text-icon {
    width:65%;
}

@media only screen and (max-width: 768px) {
    .benefit-item-text {
        padding-left: 5% !important;
        margin-top:-2%;
        word-break: keep-all;
    }

    .v-application .pt-0 {
        padding-top: 5% !important;
    }

    .text-icon {
        width:150%;
    }
}
</style>