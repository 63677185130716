<template>
    <v-dialog
            width="500"
            v-model="gender_dialog"
    >
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" tabindex="-1"  :color="form_color">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="dialog-header-text">
                Gender at Birth
                <v-spacer></v-spacer>
                <v-icon large  :color="form_color" @click="gender_dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text class="mt-0">
                <v-divider></v-divider>
                <div class="mt-2 dialog-text">
                    If you do not identify with either male or female, or if you
                    identify with a gender that
                    is different from your gender at birth, please select your
                    gender assignment at birth
                    for your application for coverage. Insurance rates are
                    determined by your sex at birth,
                    so providers require us to ask this. If you have any questions,
                    please feel free to
                    contact us - our team is committed to making sure all customers
                    feel welcome and respected.
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "gender_help_dialog",
    mixins: [quilityMixin],
    data: () => ({
        gender_dialog: false
    }),
    computed: {
        form_color: function() {
            if(!window.xprops) {
                return 'black'
            }

            let formColor = 'black'

            if(window.xprops && window.xprops.FormColor) {
                if(this.isValidHex(window.xprops.FormColor)) {
                    formColor = window.xprops.FormColor
                }
            }

            return formColor
        },
    }
}
</script>

<style scoped>
.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}
</style>
