import Vue from 'vue'
import VueRouter from 'vue-router'
import GetAQuote from "../components/GetAQuote";
import MultipleQuotation from "@/views/MultipleQuotation";
import TermCoverage from "@/views/TermCoverage";
import ChatDisclaimerDialog from "../components/ChatDisclaimerDialog";
import SymmRAQ from "../components/SymmRAQ";
import Symmetry from "../components/Symmetry";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'GetAQuote',
        component: GetAQuote
    },
    {
        path: '/termQuote',
        name: 'TermCoverage',
        component: TermCoverage
    },
    {
        path: '/quotes',
        name: 'multiple-quotes',
        component: MultipleQuotation
    },
    {
        path: '/quotes',
        name: 'multiple-quotes-edited',
        component: MultipleQuotation
    },
    {
        path: '/chat-disclaimer',
        name: 'Chat-Disclaimer',
        component: ChatDisclaimerDialog
    },
    {
        path: '/symmetry/raq/:agent_code',
        name: 'Symm-raq',
        component: Symmetry,
        children: [
            {
                path: '/',
                name: 'Symm-form',
                component: SymmRAQ
            }
        ]
    },
    {
        path: '/symmetry/quotes',
        name: 'Symm-quotes',
        component: MultipleQuotation
    }
]

const router = new VueRouter({
    routes
})

export default router
