<template>
    <div>
        <v-card style="background: white; font-family: Montserrat; " class="pa-4 mt-6" >
            <v-row>
                <v-col class="col-3">
                    <img v-bind:src="logo" alt="logo" class="ml-3 mt-5 float-left" :style="logo_style" >
                    <img v-bind:src="sub_logo" v-if="this.card_data.sub_logo_exists" class="float-left" alt="logo" :style="sub_logo_style" >

                    <div id="sbli_help_icon" v-if="sbli">
                        <v-icon tabindex="-1" style="font-size: 1.2rem;" color="#005851" class="mt-12 ml-4"
                                @click.stop="sbli_benefit_dialog = true"
                        >
                            mdi-help-circle-outline
                        </v-icon>
                        <SBLI_Benefit_Dialogue v-model="sbli_benefit_dialog"></SBLI_Benefit_Dialogue>
                    </div>
                </v-col>
                <v-col class="col-5 mt-2">
                    <v-row no-gutters class="" v-for="item in card_data.messages" :key="item">
                        <v-col class="col-1">
                                <span class="mdi mdi-check"
                                      style="color:#46C3B2;font-size: 1.2rem;font-weight: normal;"></span>
                        </v-col>
                        <v-col class="col-11 pl-1 ml-n2 d-flex align-center">
                            <span style="font-size: 0.875rem;font-weight: 300;font-family: Montserrat, Light" v-html="item"></span>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col class="col-4">
                    <v-row>
                        <v-col class="text-center pa-0 no-gutters mb-2 mt-2"
                               style="color: #9B9B9B;font-size: 0.9rem;">
                            Coverage as low as
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col style="cursor:pointer;"
                               @click="enter_eapp"
                               id="amam_getThisPlan"
                               class="text-center cursor-pointer px-6 mt-0 mb-2 "
                        >
                            <div class="amount">
                                <div class="premium"><sup>$</sup>{{card_data.premium}}</div>
                                <div class="amount-per-month">Monthly<sup>*</sup></div>
                            </div>
                            <v-btn color="q-primary" depressed class="get-started px-3 my-2">
                                Get Started
                                <img class="ml-2" src="../assets/CTA-Arrow-white.svg">
                            </v-btn>
                            <div class="premium-note">
                                This is an initial quote. Your final premium may vary.
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import SBLI_Benefit_Dialogue from "./SBLI_Benefit_Dialogue";

export default {
    name: "QuoteCard",
    components: {
        SBLI_Benefit_Dialogue,
    },
    props: {
        card_data: {
            type: Object
        }
    },
    data: () => ({
        sbli:false,
        sbli_benefit_dialog:false,
        logo: null,
        logo_style: null,
        sub_logo:null,
        sub_logo_style: null,
    }),
    mounted() {
        this.logo = this.getImgUrl(this.card_data.logo)
        this.sub_logo = (this.card_data.sub_logo_exists) ? this.getImgUrl(this.card_data.sub_logo):''
        this.sbli = (this.card_data.mode === 'sbli')


        if(this.card_data.mode === 'sbli'){
            this.logo_style = 'width:150px;margin-top:21% !important';
            if(this.card_data?.sub_logo_exists) {
                this.sub_logo_style = 'margin-top:41%; margin-left:-41%;'
            }
        }
    },
    methods:{
        getImgUrl(pic) {
            return require('../assets/'+pic)
        },
        async enter_eapp() {
            await this.$parent.process_sbli();
        }
    }

}
</script>

<style lang="scss" scoped>
$text--secondary: #9B9B9B;
.amount {
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: 0.32px;
    margin-top: -5%;
}
.amount-per-month {
    font-size: 1rem;
    font-weight: normal;
}

.premium-note {
    color: $text--secondary;
    font-size: 0.5rem;
    text-align: left;
}

.get-started {
    color: #fff !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}
</style>