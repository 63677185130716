<template>
    <div class="row " style="justify-content: center !important;">
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm :comp-data="raqData"></RAQForm>
        </div>
    </div>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";
// import SymmRaqAgentInfo from "./SymmRaqAgentInfo";
import RAQForm from "./RAQForm";



export default {
    name: "SymmRAQ",
    components: {
        RAQForm,
        // SymmRaqAgentInfo
    },
    mixins:[quilityMixin],
    data: () => ({
        agentData:null,
        hasAgency: false,
        site: 'OC',
        site_detail: {},
        raqData:null,
    }),

    methods: {
        openZChat() {
            this.openZohoChat();
        },
    },
    mounted() {
        this.getAgentInfo('OC').then(agentData => {
            // window.$zoho.salesiq.floatbutton.visible('hide');
            this.agentData = {
                'agentName': agentData.agentName,
                'agentFirstName': agentData.agentFirstName,
                'agentLastName': agentData.agentLastName,
                'agencyName': agentData.agencyName,
                'agentPhone': agentData.agentPhone,
                'agentEmail': agentData.agentEmail,
                'agentAddress': agentData.agentAddress,
                'agentCity': agentData.agentCity,
                'agentState': agentData.agentState,
                'agentZip': agentData.agentZip,
                'agentPayrollID': agentData.agentPayrollID,
                'site':'OC',
                'agentCode':agentData.agentCode,
                'agentLicenses': agentData.agentLicenses,
                'amam_writingNumbers': agentData.amam_writingNumbers,
                'Signature': agentData.Signature,
                'ExtUID': agentData.ExtUID,
                'AffiliateCode': agentData.AffiliateCode,
            }
            if(agentData.agencyName != '' && typeof agentData.agencyName!='undefined') {
                this.hasAgency = true;
            }

            this.raqData = {
                'agentInfo': this.agentData,
                'site':'OC'
            }

        });
    }
}
</script>

<style scoped>


.estimate-msg {
    font-size: 3rem;
    color: black;
    font-family: freight-display-pro, Sans-serif !important;
    line-height: 1.3em;
    font-weight: 900;
}

.med-thin-text {
    margin-top: 3%;
    font-size: 1.7rem;
    font-family: Montserrat, Light !important;
    font-weight: 300;
    line-height: 2.5rem;
    color: #000000;
}

.agency-stmt {
    font-family: Montserrat, Light !important;
    font-size: 1.3rem !important;
}

.breaking-border {
    background: #005851;
    width: 51.58px;
    height: 8.38px;
    margin-top: 2%;
}

.price-teaser-period {
    font-size: 0.8rem;
}

.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}





@media only screen and (max-width: 768px) {
    .estimate-msg {
        font-family: freight-display-pro, Sans-serif !important;
        font-size: 2rem;
        line-height: 1.2em;
        margin-bottom:0;

    }

    .get-a-quote-block {
        font-size: 0.8rem;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    .price-teaser {
        margin-top:14%;
    }

    .price-teaser-period {
        margin-top:6%;
        font-size: 0.8rem;
        white-space: nowrap;
        word-break: keep-all;
    }

    input::-webkit-input-placeholder{
        color:red;
    }
    input:-moz-placeholder {
        color:red;
    }
}
</style>
