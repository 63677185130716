<template>
    <div class="scenario-mobile-title hidden-md-and-up">
        <v-row class="mt-10 mb-5">
            <v-col class="text-center">
                <div style="font-weight: bold; color:black;font-size: 0.83rem;">Your Coverage</div>
                <EditQuoteDialog v-model="edit_quote_dialog"
                                 :coverage_items="coverage_items"
                                 :plan_items="plan_items"
                                 :assign-values-method="callback"
                                 ref="edit_quote_dialogue"
                ></EditQuoteDialog>
            </v-col>
            <v-col class="text-center">
                <div style="font-weight: bold; color:black;font-size: 0.83rem;">{{term_length}}</div>
                <div style="font-weight: normal; color:black;font-size: 0.83rem;">Term Length</div>
            </v-col>
            <v-divider
                    vertical
            ></v-divider>
            <v-col class="text-center">
                <div style="font-weight: bold; color:black;font-size: 0.83rem;"><sup class="cov_sup">$</sup>{{coverage_amount}}</div>
                <div style="font-weight: normal; color:black;font-size: 0.83rem;">Coverage</div>
            </v-col>
        </v-row>

        <div v-if="quote_array.length > 0">
            <QuoteCardMobile v-for="(item, idx) in quote_array" :card_data="item" :key="idx"></QuoteCardMobile>
        </div>
        <div v-else>
            <v-card class="pa-4 mt-5 py-7 q-card">
                <v-row>
                    <v-col class="col-12 no-gutters pr-1">
                        <v-row>
                            <v-col style="font-size:1.2rem;font-family: Montserrat, Bold; font-weight: bold">
                                No quotes were found.
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <v-card v-if="show_talk_to_agent" class="pa-4 mt-5 py-7 q-card">
            <v-row>
                <v-col class="col-7 no-gutters pr-1" style="border-right: 1px solid #cccccc; ">
                    <v-row>
                        <v-col style="font-size:1.2rem;font-family: Montserrat, Bold; font-weight: bold">
                            Meet a Licensed Agent
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="py-2">
                        <v-col class="no-gutters pa-0" style="font-size: 0.81rem;font-weight: 300; color:black;">
                            Discuss your options with a licensed agent who can help you find your best-fit plan
                        </v-col>
                    </v-row>
                </v-col>

                <v-col class="col-5 text-center">
                    <v-btn
                            elevation="2"
                            fab
                            large
                            style="background: #005851;"
                            class="mb-1"
                            @click="FindAdvisor"
                    >
                        <img src="../assets/Talk-It-Through-Icon.svg">
                    </v-btn><br>
                    <span @click="FindAdvisor" style="font-family: Montserrat, SemiBold; font-weight: 600; color: #46C3B2;cursor: pointer;font-size: 0.88rem;" class="mt-1" id="mobileFindAnAdvisor">Find a Licensed Agent <img src="../assets/CTA Arrow.svg"></span>
                </v-col>
            </v-row>
        </v-card>
        <WidgetFooter></WidgetFooter>
        <a ref="raq_a_tag" href="" target="_blank"></a>
    </div>

</template>

<script>
import EditQuoteDialog from "@/components/EditQuoteDialog";
import QuoteCardMobile from "./QuoteCardMobile";
import quilityMixin from "@/mixins/quilityMixin";
import WidgetFooter from "@/components/WidgetFooter.vue";

export default {
    mixins: [quilityMixin],
    name: "am_card_mobile",
    props: [
        'tobacco_used',
        'coverage_amount',
        'term_length',
        'coverage_items',
        'plan_items',
        'callback',
        'btnDisabled',
        'loading',
        'quote_array',
        'zoho_id',
    ],
    data: () => ({
        amam_benefit_dialog: false,
        edit_quote_dialog:null,
        m_coverage_amount: 0,
        m_term_length: 0,
        m_tobacco_used: 0,
    }),
    components: {
        QuoteCardMobile,
        EditQuoteDialog,
        WidgetFooter,
    },
    watch: {
        m_coverage_amount: function(val) {
            this.m_coverage_amount = val;
        }
    },
    mounted() {
        this.m_coverage_amount = new Intl.NumberFormat().format(this.coverage_amount);
    },
    methods:{
        async process_eapps() {
            await this.$parent.process_sbli()

            if(this.sbli_redirect !== null) {
                // window.open(this.aff_redirect_url, "_blank");
                this.$refs.raq_a_tag.href = this.sbli_redirect
                this.$refs.raq_a_tag.click()
            }
        },
        FindAdvisor() {
            let thank_you_redirect_url = process.env.VUE_APP_TERM_CONSULT_URL

            if (window.xprops) {
                if(window.xprops.ThankYouUrl){
                    thank_you_redirect_url = window.xprops.ThankYouUrl
                }
            }

            this.updateDisposition(this.zoho_id, 'RAQ Consult', thank_you_redirect_url, true)
        },
    },
    computed: {
        show_talk_to_agent: function() {
            let show = true

            if (window.xprops) {
                if(window.xprops.TalkToAgent != null){
                    show = window.xprops.TalkToAgent
                }
            }

            return show
        }
    },
}
</script>

<style scoped>
.scenario-mobile-title {
    font-family: Montserrat;
}
.q-card {
    font-family: Montserrat;
}
.coverage-title {

    color: #9B9B9B;font-size: 0.69rem;
}

.amount-box {
    background: #005851;color: white; cursor:pointer;
}

.amount div.premium {
    font-family: Montserrat, ExtraBold;
    font-size: 2.0rem;
    font-weight: 800;
    white-space: pre-wrap;
    word-break: keep-all;
}

.amount div.amount-per-month {
    font-weight: 300;
    font-size:0.69rem;
    margin-top:-5%
}

.get-started {
    color: #46C3B2;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size:0.8rem
}

.check-mark {
    color:#46C3B2;font-size: 1rem;font-weight: normal;
}

.benefit-item {
    font-size: 0.82rem;font-weight: 300;font-family: Montserrat, Light;
    color:black;
}

@media only screen and (max-width: 768px) {
    .cov_sup {
        font-size:0.6rem;
    }

    .amount div.premium {
        font-size: 1.5rem;
    }
}

</style>
