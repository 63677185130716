<template>
    <NoQoutes
        v-if="do_show_no_qoutes_error"
        :coverage_items="coverage_items"
        :plan_items="plan_items"
        :assign-values-method="assignValues"
        :loading="loading"
        @meetAgentClick="FindAdvisor"
    />
    <div v-else>
        <v-container class="hidden-sm-and-down">
            <div class="large-device-container pa-md-10" >
                <div class="text-center" style="color: #005851; font-weight: 800; font-size: 1.2rem;">
                    Your Coverage
                </div>
                <v-card flat style="font-family: Montserrat" class="pa-4">
                    <v-row>
                        <v-col>
                            <EditQuoteDialog v-model="edit_quote_dialog"
                                             :coverage_items="coverage_items"
                                             :plan_items="plan_items"
                                             :assign-values-method="assignValues"
                                             ref="edit_quote_dialogue"
                            ></EditQuoteDialog>
                        </v-col>
                        <v-col class="text-left" style="flex-grow: 2">
                            <v-row style="color:black;">
                                <v-col class="text-center v-divider--vertical pr-0">
                                    <div >
                                        Term Length<br>
                                        <span style="font-weight: bold;font-size:1.5rem">{{selected_term_length}}</span>
                                    </div>
                                </v-col>

                                <v-col class="text-center">
                                    <div>
                                        Coverage <br>
                                        <span style="font-weight: bold;font-size:1.5rem"><sup>$</sup>{{selected_Coverage}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </v-card>

                <div v-if="card_data.length > 0">
                    <QuoteCard v-for="(data, idx) in card_data" :key="idx" :card_data="data" ></QuoteCard>
                </div>
                <div v-else>
                    <v-card class="pa-4 mt-5 py-7 q-card">
                        <v-row>
                            <v-col class="col-12 no-gutters pr-1">
                                <v-row>
                                    <v-col style="font-size:1.2rem;font-family: Montserrat, Bold; font-weight: bold">
                                        No quotes were found.
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>

                <v-card v-if="show_talk_to_agent" style="background: white; font-family: Montserrat; " class="pa-4 mt-6">
                    <v-row >
                        <v-col style="font-size:1.1rem;font-family: Montserrat, Bold; font-weight: bold"
                               class="text-center d-flex align-center justify-center pl-5 col-3">
                            Meet a Licensed Agent
                        </v-col>
                        <v-col style="font-size: 0.875rem; font-weight: 300; display: flex; align-items: center;" class="d-flex align-center col-5">
                            Discuss your options with a licensed agent who can help you find your best-fit plan
                        </v-col>
                        <v-col class="text-center col-4">
                            <v-btn
                                    elevation="2"
                                    fab
                                    large
                                    style="background: #005851;"
                                    class="mb-1"
                                    @click="FindAdvisor"
                            >
                                <img src="../assets/Talk-It-Through-Icon.svg">
                            </v-btn>
                            <br>
                            <span style="font-family: Montserrat, SemiBold; font-weight: 600; color: #46C3B2;cursor: pointer;"
                                  class="mt-1" @click="FindAdvisor" id="multipleQuotesFindAdvisor">Find a Licensed Agent <img src="../assets/Group 5813.svg"></span>
                        </v-col>
                    </v-row>
                </v-card>

            </div>
            <v-dialog
                    v-model="eApp_dialog"
                    persistent
                    width="300"
                    style="font-family: montserrat, Sans-serif"
            >
                <v-card
                        color="#005851"
                        dark
                >
                    <v-card-text style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;"
                                 class="pa-3">
                        <span v-if="open_app_in_new_page" class="text-center">
                            <span v-if="sbli_redirect">If a new window does not open, then <a :href=sbli_redirect target="_blank" style="color: inherit;">click here</a> to open.</span>
                            <span v-else>Opening Application in a new page</span>
                        </span>
                        <span v-else>Starting e-Application</span>
                        <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0 mt-4"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <div class="hidden-md-and-up">

            <am_card_mobile
                    :am_premium="amam_premium"
                    :am_premium_exist="amam_premium_exist"
                    :sureify_method="process_sureify"
                    :tobacco_used="m_tobacco_used"
                    :coverage_amount="coverage_amount"
                    :term_length="m_term_length"
                    :coverage_items="coverage_items"
                    :plan_items="plan_items"
                    :callback="assignValues"
                    :quote_array="card_data"
                    :zoho_id ="zoho_id"
            ></am_card_mobile>
        </div>
        <QuoteErrorDialog v-model="error_dialogue"></QuoteErrorDialog>
    </div>
</template>

<script>
import QuoteCard from "./QuoteCard";
window._mfq = window._mfq || [];
(function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/bc45607e-c708-40a4-b5de-cfecbe5be187.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
})();

import numeral from "numeral";
import axios from "axios";


import am_card_mobile from "@/components/am_card_mobile";
import EditQuoteDialog from "@/components/EditQuoteDialog";

import quilityMixin from "@/mixins/quilityMixin";
import QuoteErrorDialog from "./QuoteErrorDialog";
import NoQoutes from './NoQoutes.vue'

let moment = require('moment');

export default {
    name: "MultipleQuotations",
    mixins: [quilityMixin],
    data: () => ({
        error_dialogue:false,
        amam_benefit_dialog: false,
        edit_quote_dialog:null,
        eApp_dialog: false,
        amam_dialog_help: false,
        loading: false,
        amam_premium: 0,
        amam_product: null,
        amam_premium_exist: true,
        sbli_premium:0,
        selected_term_length: 0,
        selected_Coverage: 0,
        m_coverage_amount:0,
        m_term_length:0,
        m_tobacco_used:null,
        zoho_id: null,
        plan_items:  [
            {text: '10 Years', value: '10 Years'},
            {text: '15 Years', value: '15 Years'},
            {text: '20 Years', value: '20 Years'},
            {text: '25 Years', value: '25 Years'},
            {text: '30 Years', value: '30 Years'},
        ],
        btnDisabled:false,
        dialog:false,
        getQuoteData: null,
        coverage_amount:0,
        quoteContainerClass: null,
        agencyName: null,
        agentName: null,
        agentPhone: null,
        agentEmail: null,
        agentAddress: null,
        agentCity: null,
        agentState: null,
        agentZip: null,
        agentPayrollID: null,
        agentId: null,
        agentGovtId: null,
        card_data: [],
        state_id: null,
        arcId: null,
        agentInfo:null,
    }),
    components: {
        QuoteErrorDialog,
        QuoteCard,
        am_card_mobile,
        EditQuoteDialog,
        NoQoutes,
    },
    mounted() {
        const parameters = this.$route.params;
        if(typeof parameters.form_data !== 'undefined') {
            this.getPremiums(parameters.quotes);
            this.assignValues(parameters.form_data, parameters.quotes);
            this.zoho_id = parameters.zoho.ZohoID;
            this.getQuoteData = parameters;
        }

        if(this.$vuetify.breakpoint.name === 'lg'){
            this.quoteContainerClass = 'large-device-container';
        }

        this.state_id = parameters.form_data.state_id;


        if(typeof parameters.agentInfo !== 'undefined') {
            this.agentInfo = parameters.agentInfo;
            this.agentName = parameters.agentInfo.agentName;
            this.agentPhone = parameters.agentInfo.agentPhone;
            this.agentAddress = parameters.agentInfo.agentAddress;
            this.agentCity = parameters.agentInfo.agentCity;
            this.agentZip = parameters.agentInfo.agentZip;
            this.agentState = parameters.agentInfo.agentState;
            this.agentPayrollID = parameters.agentInfo.agentPayrollID;
            this.agentGovtId = parameters.agentInfo.agentGovtId;
            this.agentEmail = parameters.agentInfo.agentEmail;


            if(parameters.site === 'OC') {
                const agentLicenses = parameters.agentInfo.agentLicenses;
                const agentLicensed = (agentLicenses.includes(parameters.form_data.state_id));

                if(agentLicensed!=true) {
                    const agentInfo = this.getDefaultOCAgent(); //pick brandon to go to JH eApp
                    this.agentName = agentInfo.agentName;
                    this.agentPhone = agentInfo.agentPhone;
                    this.agentAddress = agentInfo.agentAddress;
                    this.agentCity = agentInfo.agentCity;
                    this.agentZip = agentInfo.agentZip;
                    this.agentState = agentInfo.agentState;
                    this.agentPayrollID = agentInfo.agentPayrollID;
                    this.agentGovtId = agentInfo.agentGovtId;
                    this.agentEmail = agentInfo.agentEmail;
                }
            }
        }

        this.generateQuoteCards();
        if(this.card_data.length == 0){
            if(this.open_app_in_new_page) {
                window.open(this.error_url, "_blank")
            } else {
                window.location.href = this.error_url
            }
        }
    },
    created() {
        const parameters = this.$route.params;
        const query_param = this.$route.query.q;

        if(typeof parameters.form_data === 'undefined' && typeof query_param === 'undefined') {
            this.$router.push({name: 'GetAQuote'});
        }

        // console.log(parameters);
        this.selected_term_length = parameters.form_data.term_length;
        this.selected_Coverage = this.formatCurrency(parameters.form_data.coverage_amount);
    },
    methods: {
        FindAdvisor() {
            let thank_you_redirect_url = process.env.VUE_APP_TERM_CONSULT_URL

            if (window.xprops) {
                if(window.xprops.ThankYouUrl){
                    thank_you_redirect_url = window.xprops.ThankYouUrl
                }
            }

            this.updateDisposition(this.zoho_id, 'RAQ Consult', thank_you_redirect_url, true)
        },
        getPremiums(quotes) {
            this.amam_premium_exist = false
            quotes.forEach((item) => {
                if(item.Coverage == null || item.Premium == null) {
                    return
                }
                this.selected_term_length = item.Term
                this.selected_Coverage = this.formatCurrency(item.Coverage)

                    this.arcId = (item.arcId != null && item.arcId != undefined) ? null : item.arcId
                    this.sbli_premium = parseFloat(item.Premium).toFixed(2)
            })
        },

        formatCurrency(a) {
            return numeral(a).format('0,0[.]00');
        },
        setCoverageItems(coverage_limit) {
            let r = []
            for (let i = 0; i < coverage_limit; i++) {
                let amt = (25000 + (i * 25000));
                r.push({
                    'text': this.formatCurrency(amt),
                    'value': amt
                })
            }
            r.reverse();
            return r;
        },

        checkQuote(coverage_amt, term_length, tobacco_used) {
            this.loading = true;
            this.btnDisabled = true;
            let ste_url = process.env.VUE_APP_PROXY + 'get-quote';
            const parameters = this.$route.params;

            let postParam = {
                gender: parameters.form_data.gender,
                productType:'Term',
                smoker:tobacco_used,
                zip:parameters.form_data.zip,
                dob:parameters.form_data.dob,
                coverage_amount: coverage_amt,
                term_length: term_length,
                firstName: parameters.form_data.firstName,
                lastName: parameters.form_data.lastName,
                email: parameters.form_data.email,
                state_id: parameters.form_data.state_id,
                city: parameters.form_data.city,
                age: parameters.form_data.age,
                entry_point: parameters.form_data.entry_point,
                phone: parameters.form_data.phone,
                zoho_id: this.zoho_id,
                mode:'edit',
                division: this.division,
            }

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.post(ste_url, postParam)
                    .then(res => {
                        this.loading = false;
                        this.edit_quote_dialog = false;
                        this.$refs.edit_quote_dialogue.closeDialog();
                        if(res.data.success === true ) {
                            this.loading = false;
                            this.btnDisabled = false;
                            let quoteParams = {};
                            quoteParams['quotes'] = res.data.quotes;
                            quoteParams['form_data'] = postParam;
                            // this.$router.push({ name: 'TermCoverage', params: quoteParams })
                            this.assignValues(postParam, res.data.quotes);
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    });
        },

        assignValues(form_data, quotes) {
            const coverageAmt = form_data.coverage_amount;
            this.coverage_amount = new Intl.NumberFormat().format(coverageAmt);
            this.term_length = form_data.term_length;

            this.m_coverage_amount = coverageAmt;
            this.m_term_length = form_data.term_length;
            this.m_tobacco_used = form_data.smoker;

            this.getPremiums(quotes);
            this.generateQuoteCards();
        },

        process_sureify() {
            if(this.amam_premium_exist === false) {
                return false;
            }
            this.eApp_dialog = true;
            const routeParam = this.$route.params;
            let parameters = routeParam.form_data;

            if(routeParam.site === 'QUILITY') {
                parameters.agentInfo = this.getDefaultOCAgent(); // use Brandon for quility amam
            }

            const agentLicenses = routeParam.agentInfo.agentLicenses;
            const agentLicensed = (agentLicenses.includes(parameters.state_id));

            const lead_info = {};
            lead_info['premium'] = this.amam_premium;
            lead_info['zoho_id'] = this.zoho_id;
            lead_info['product'] = this.amam_product;
            lead_info['disposition'] = 'RAQ Apply Now';

            // eslint-disable-next-line no-unused-vars
            this.updateLeadProductPremium(parameters, lead_info, 'amam').then(res => {
                // console.log(res);
                let ste_url = process.env.VUE_APP_PROXY + 'process-sureify';
                const quote_data = {};
                quote_data.ZohoID = this.$route.params.zoho.ZohoID;
                quote_data.premium = this.amam_premium;
                quote_data.site = routeParam.site;
                if(!agentLicensed) {
                    quote_data.agentInfo = this.getDefaultOCAgent();
                }
                const postParam = {...parameters, ...quote_data}

                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                axios.post(ste_url, postParam).then(response => {
                    // console.log(response);
                    window.location.href = response.data.response.pathname;
                }).catch(error => {
                    console.log(error);
                    lead_info['disposition'] = 'Quote Error';

                    // eslint-disable-next-line no-unused-vars
                    this.updateLeadProductPremium(parameters, lead_info, 'amam').then(res => {
                        // console.log(res);
                    }).catch(error => {console.log(error)});
                    this.eApp_dialog = false;
                    this.error_dialogue = true;
                });
            }).catch(error => {
                console.log(error);
                this.error_dialogue = true;
                this.eApp_dialog = false;
            });
        },

        async process_sbli() {
            this.eApp_dialog = true;
            const parameters = this.$route.params;

            const lead_info = {};
            lead_info['premium'] = this.sbli_premium;
            lead_info['zoho_id'] = this.zoho_id;
            lead_info['product'] = 'Quility Level Term';
            lead_info['disposition'] = 'RAQ Apply Now';

            let useSBLIDemo = false
            let useExtUID = false

            if(window.xprops && window.xprops.UseSBLIDemo) {
                useSBLIDemo = window.xprops.UseSBLIDemo
            }

            if(window.xprops && window.xprops.useExternalAgentCode) {
                useExtUID = window.xprops.useExternalAgentCode
            }

            // eslint-disable-next-line no-unused-vars
            try {
                await this.updateLeadProductPremium(parameters.form_data, lead_info, 'sbli')

                const phone = parameters.form_data.phone;
                let phone_clean = phone ? phone.replace('(', '') : null;
                phone_clean = phone_clean ? phone_clean.replace(')','') : null;

                let tyURL = null

                if (window.xprops) {
                    if(window.xprops.ThankYouUrl){
                        tyURL = window.xprops.ThankYouUrl
                    }
                }

                const postParam = {
                    email: parameters.form_data.email,
                    leadReferenceId:parameters.zoho ? parameters.zoho.ZohoID : null,
                    phone:phone_clean,
                    zip: parameters.form_data.zip,
                    firstname: parameters.form_data.firstName,
                    lastname: parameters.form_data.lastName,
                    dob: moment(parameters.form_data.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                    gender: parameters.form_data.gender,
                    state: parameters.form_data.state_id,
                    coverage:parameters.form_data.coverage_amount,
                    term:parameters.form_data.term_length,
                    smoker: parameters.form_data.smoker,
                    arcId: this.arcId,
                    agentCode: (parameters.site === 'OC') ? parameters.agentInfo.agentCode : process.env.VUE_APP_QLTCORPAGENT,
                    UseSBLIDemo: useSBLIDemo,
                    UseExtUID: useExtUID,
                    ExtUID: parameters.agentInfo.ExtUID,
                    ThankYouUrl: tyURL,
                    UseAffiliateCode: this.use_affiliate_code,
                    AffiliateCode: parameters.agentInfo.AffiliateCode,
                };

                let ste_url = process.env.VUE_APP_PROXY + 'get-sbli-url';
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                const response = await axios.post(ste_url, postParam)

                if(response.data.success == false) {
                    lead_info['disposition'] = 'Quote Error';
                    await this.updateLeadProductPremium(parameters, lead_info, 'sbli')
                    this.eApp_dialog = false;
                    return false;
                }

                if(this.open_app_in_new_page) {
                    // this.aff_redirect_url = response.data.data.redirectUrl
                    this.$store.commit('updateSBLIRedirect', {site: response.data.data.redirectUrl})
                } else {
                    window.location.href = response.data.data.redirectUrl;
                }
            } catch (error) {
                console.error(error)
                lead_info['disposition'] = 'Quote Error';
                this.eApp_dialog = false;
                this.error_dialogue = true;
            }

            // console.log('aff_redirect_url', this.aff_redirect_url)
            // if(this.aff_redirect_url !== null) {
            //     window.open(this.aff_redirect_url, "_blank");
            // }
        },

        generateQuoteCards() {
            this.card_data = [];

            if(typeof this.sbli_premium!='undefined' && this.sbli_premium > 0 && this.state_id != 'NY') {
                const sbli_card = {
                    mode: 'sbli',
                    premium: this.sbli_premium,
                    logo: 'QLT-image.png',
                    mobile_logo_exists: true,
                    // sub_logo: 'Backed-By-SBLI.svg',
                    sub_logo_exists: false,
                    mobile_logo: 'QLT-image.png',
                    messages: [
                        'Coverage up to <sup>$</sup>1 Million',
                        '10 to 30-year terms',
                        'No medical exam<sup>1</sup>'
                    ],
                    mobile_msg: [
                        'Coverage up to <sup>$</sup>1 Million',
                        '10 to 30-year terms',
                        'No medical exam<sup>1</sup>'
                    ],
                    method: 'process_sbli'
                }
                this.card_data.push(sbli_card);
            }

            this.card_data.sort((a, b) => (parseInt(a.premium) < parseInt(b.premium)) ? -1 : 1);
        }

    },
    computed: {
        coverage_items: {
            get() {
                return this.setCoverageItems(40);
            },
            set(newCoverageItems) {
                return newCoverageItems
            }
        },
        show_talk_to_agent: function() {
            let show = true

            if (window.xprops) {
                if(window.xprops.TalkToAgent != null){
                    show = window.xprops.TalkToAgent
                }
            }

            return show
        },
        form_color: function() {
            if(!window.xprops) {
                return 'black'
            }

            let formColor = 'black'

            if(window.xprops && window.xprops.FormColor) {
                if(this.isValidHex(window.xprops.FormColor)) {
                    formColor = window.xprops.FormColor
                }
            }

            return formColor
        },
        do_show_no_qoutes_error() {
            return this.card_data.length === 0
        },
        division: function() {
            if(!window.xprops) {
                return false
            }

            let div = 'Quility'

            if(window.xprops && window.xprops.Division) {
                div = window.xprops.Division
            }

            return div
        },
    },
    watch: {
        m_coverage_amount: function(val) {
            return val;
        }
    }
}
</script>

<style scoped>
.large-device-container {
    font-family: Montserrat;
    /*width: 90%;*/
    /*align-content: center;*/
}
</style>
