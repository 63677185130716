<template>
    <v-container class="q-no-qoutes px-8">
        <v-row>
            <EditQouteDialog :coverage_items="coverage_items" :plan_items="plan_items" :assignValuesMethod="assignValuesMethod" />
        </v-row>
        <v-row class="mt-12">
            <v-progress-linear v-if="loading" indeterminate color="q-secondary px-12"></v-progress-linear>
            <h3 class="font-weight-bold mb-4">Thanks for your interest in life insurance coverage through Quility!</h3>

            <p class="font-weight-light mb-8">
                Based on the information you provided, you unfortunately don’t qualify for the coverage options offered through our online application.
            </p>

            <p class="mb-12">
                <strong class="font-weight-medium">
                    A licensed agent will be happy to assist you in finding a life insurance product that best suits your needs, either virtually, over the phone or in person!
                </strong>
            </p>

            <v-btn color="q-secondary px-12" large depressed dark @click="$emit('meetAgentClick')">
                Meet an Agent
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import EditQouteDialog from './EditQuoteDialog.vue'

export default {
    name: "NoQoutes",

    components: {
        EditQouteDialog
    },

    props: [
        'coverage_items',
        'plan_items',
        'assignValuesMethod',
        'loading'
    ],
}
</script>

<style lang="scss" scoped>
    .q-no-qoutes {
        * {
            font-family: Montserrat;
        }
        // h3 {
        //     font: normal normal 900 14px/21px Montserrat;
        // }
        // p {
        //     font: normal normal normal 14px/21px Montserrat;

        //     strong {
        //         font: normal normal 700 13px/21px Montserrat !important;
        //     }
        // }
        .v-btn {
            font: normal normal medium 12px/10px Montserrat;
        }
    }
</style>
