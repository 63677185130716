<template>
    <v-dialog
            width="500"
            v-model="tobacco_dialog"
    >
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" tabindex="-1"  :color="form_color">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="dialog-header-text">
                Tobacco Use
                <v-spacer></v-spacer>
                <v-icon large  :color="form_color" @click="tobacco_dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="mt-0">
                <v-divider></v-divider>
                <div class="mt-2 dialog-text">
                    Good news! Our preferred insurance partner allows occasional
                    pipe and cigar users to select "no."
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "tobacco_help_dialog",
    mixins: [quilityMixin],
    data: () => ({
        tobacco_dialog: false,
    }),
    computed: {
        form_color: function() {
            if(!window.xprops) {
                return 'black'
            }

            let formColor = 'black'

            if(window.xprops && window.xprops.FormColor) {
                if(this.isValidHex(window.xprops.FormColor)) {
                    formColor = window.xprops.FormColor
                }
            }

            return formColor
        },
    }
}
</script>

<style scoped>
.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}
</style>
