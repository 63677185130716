<template>
    <v-dialog width="580" v-model="show">
        <v-card>
            <v-card-title
                    class="dialog-header-text ">
                Requires Consultation
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click.stop="closeDialog">
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text class="mt-0 ">
                <v-divider></v-divider>
                <v-row class="pa-5 ">
                    <v-col class="col-12 pl-0 ml-n2" style="font-size: 1rem; font-family: Montserrat, Light; font-weight: 300; color: black;">
                        Based on the information provided, one of our advisors will reach out to you to ensure you get the best rate available.

                        <v-btn class="input-control-text mt-md-8 mt-sm-6 mt-xs-6 ctn-btn" style="text-transform: none;"
                               type="button" @click="requestConsultation" large dark block depressed color="#005851">
                            Continue
                        </v-btn>
                        <v-progress-linear class="mt-6" v-if="redirecting"
                                           indeterminate
                                           color="#46C3B2"
                        >
                        </v-progress-linear>

                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "SubscriptionDialog",
    props:['visible', 'zohoId'],
    mixins: [quilityMixin],
    data:() => ({
        redirecting: false,
    }),
    computed: {
        show: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                if(!value) {
                    this.$emit('input', value);
                }
            }
        },
        zoho_id: {
            get() {
                return this.zohoId;
            },
            set(value) {
                return value;
            }
        }
    },
    methods:{
        requestConsultation() {
            this.redirecting = true;
            if(this.zoho_id === null) {
                return false;
            }
            this.updateDisposition(this.zoho_id, 'RAQ Consult', process.env.VUE_APP_TERM_CONSULT_URL);
        },
        closeDialog() {
            this.redirecting = true;
            this.updateDisposition(this.zoho_id, 'RAQ Consult', process.env.VUE_APP_TERM_CONSULT_URL);
        }
    }
}
</script>

<style scoped>
    .card-title {
        font-weight: bold;
        font-size: 2rem;
        font-family: Georgia;
        color:black;
    }

    @media only screen and (max-width: 768px) {
        .card-title {
            font-size: 1rem;
        }

        .ctn-btn {
            margin-top: 6%;
        }
    }
</style>
