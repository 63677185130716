<template>
    <div class="row">
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm :comp-data="raqData"></RAQForm>
        </div>
    </div>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

import RAQForm from "./RAQForm";


export default {
    name: "GetAQuote",
    components: {
        RAQForm,
    },
    mixins:[quilityMixin],
    data: () => ({
        valid: false,
        agentData: {},
        raqData:{},
        product_type:'Term Life'
    }),
    methods: {
        openZChat() {
            this.openZohoChat();
        }

    },
    mounted() {
        const productType = this.$route.query.p;
        this.product_type = (typeof productType!='undefined' && productType.toUpperCase() === 'MP') ? 'Mortgage Protection' : 'Term Life';
        this.raqData.site = 'QUILITY';
        this.raqData.agentInfo = this.getAgentInfo('QUILITY');

        if(window.xprops && window.xprops.AgentCode) {
            this.$router.push({ name: 'Symm-form', params: { agent_code: window.xprops.AgentCode } })
        }
    }
}
</script>

<style scoped>

.get-a-quote-title {
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    text-align: left;
    line-height: 1em;
    color: #002421;
}

.estimate-msg {
    font-size: 3rem;
    color: #46C3B2;
    font-family: freight-display-pro, Sans-serif !important;
    line-height: 1.3em;
    font-weight: 900;
}

.med-thin-text {
    margin-top: 3%;
    font-size: calc(18px + (35 - 22) * ((100vw - 400px) / (2000 - 400))) !important;
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    line-height: 2.5rem;
    color: #000000;
}

.breaking-border {
    background: #DAF3F0;
    width: 51.58px;
    height: 8.38px;
    margin-top: 2%;
}


.price-teaser-period {
    font-size: 0.8rem;
}

.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}

.theme--light.v-btn.v-btn--has-b {
    background-color: #005951;
    color: white;
}

@media only screen and (max-width: 768px) {
    .estimate-msg {
        font-family: freight-display-pro, Sans-serif !important;
        font-size: 2rem;
        line-height: 1.2em;
        margin-bottom:0;

    }

    .get-a-quote-block {
        font-size: 0.8rem;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    .price-teaser {
        margin-top:14%;
    }

    .price-teaser-period {
        margin-top:6%;
        font-size: 0.8rem;
        white-space: nowrap;
        word-break: keep-all;
    }

    input::-webkit-input-placeholder{
        color:red;
    }
    input:-moz-placeholder {
        color:red;
    }
}
</style>
