<template>

    <div class="editQuoteDialogue">

            <label @click="OpenDialog" style="color:#46C3B2; cursor: pointer;font-size: 0.8em;" class="ml-n5 pt-10">Edit
                Quote</label>

        <v-dialog v-model="show" width="500" >
            <v-card>
                <v-card-title class="dialog-header-text">
                    Edit Quote
                    <v-spacer></v-spacer>
                    <v-icon large color="#46C3B2" @click="show = false"> mdi-close</v-icon>
                </v-card-title>

                <v-card-text class="mt-0">
                    <v-divider></v-divider>
                    <v-form class="mt-4">
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                        label="Coverage Amount"
                                        outlined
                                        v-model="m_coverage_amount"
                                        hide-details="auto"
                                        dense
                                        :items="coverage_items"
                                        color="black"
                                        ref="coverage_amt"
                                        class="input-control-text">
                                    <template v-slot:append-outer >
                                        <Cov_amt_help_dialog></Cov_amt_help_dialog>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        dense
                                        label="Term Length"
                                        outlined v-model="m_term_length"
                                        hide-details="auto"
                                        :items="plan_items"
                                        class="dropdown-style input-control-text "
                                >
                                    <template v-slot:append-outer>
                                        <Term_length_help_dialog></Term_length_help_dialog>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select label="Tobacco used in last 12 months"
                                          :items="['Yes', 'No']"
                                          dense v-model="m_tobacco_used"
                                          outlined hide-details="auto" class="input-control-text"
                                          >
                                    <template v-slot:append-outer>
                                        <Tobacco_help_dialog></Tobacco_help_dialog>
                                    </template>
                                </v-select>
                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn class="input-control-text btn-text"
                           type="button" large dark block depressed color="#005851"
                           @click="checkQuote"
                           :disabled="btnDisabled">Save Changes
                    </v-btn>
                    <v-progress-linear
                            :active="loading"
                            :indeterminate="loading"
                            absolute
                            bottom
                            color="black accent-4"
                    ></v-progress-linear>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import axios from "axios";
import Cov_amt_help_dialog from "@/components/cov_amt_help_dialog";
import Term_length_help_dialog from "@/components/term_length_help_dialog";
import Tobacco_help_dialog from "@/components/tobacco_help_dialog";

export default {
    name: "EditQuoteDialog",
    components: {Tobacco_help_dialog, Term_length_help_dialog, Cov_amt_help_dialog},
    props: [
        'coverage_items',
        'plan_items',
        'assignValuesMethod'
    ],
    data:() => ({
        coverage_amt_dialog: false,
        term_length_dialog:false,
        tobacco_dialog: false,
        routeParams: null,
        m_coverage_amount: 0,
        m_term_length: 0,
        m_tobacco_used: 0,
        show: false,
        zoho_id: null,
        btnDisabled: false,
        loading:false,
        agentInfo: {}
    }),
    mounted() {
        if(typeof this.$route.params.form_data !== 'undefined') {
            this.routeParams = this.$route.params;
            this.m_coverage_amount = this.routeParams.form_data.coverage_amount;
            this.zoho_id = this.routeParams?.zoho?.ZohoID;
            this.m_term_length = this.routeParams.form_data.term_length;
            this.m_tobacco_used = this.routeParams.form_data.smoker;
            this.agentInfo = this.routeParams.agentInfo;
        }
    },
    watch: {
        routeParams: function(val) {
            this.m_coverage_amount = val.form_data.coverage_amount;
            this.zoho_id = val.zoho?.ZohoID;
            this.m_term_length = val.form_data.term_length;
            this.m_tobacco_used = val.form_data.smoker;
        }
    },
    computed: {
        m_btnDisabled: {
            get() {
                return this.btnDisabled
            },
            set(value) {
                return value;
            }
        },
        m_loading: {
            get() {
                return this.loading;
            },
            set(value) {
                return value;
            }
        },
        currentPathName() {
            return this.$route.name;
        },
        anonymous_leads: function() {
            if(!window.xprops) {
                return false
            }

            let anonLeads = false

            if(window.xprops && window.xprops.AnonymousLeads) {
                anonLeads = window.xprops.AnonymousLeads
            }

            return anonLeads
        },
        division: function() {
            if(!window.xprops) {
                return false
            }

            let div = 'Quility'

            if(window.xprops && window.xprops.Division) {
                div = window.xprops.Division
            }

            return div
        },
    },

    methods: {
        checkQuote() {
            this.loading = true;
            this.btnDisabled = true;
            let ste_url = process.env.VUE_APP_PROXY + 'get-quote';
            const parameters = this.$route.params;

            let postParam = {
                gender: parameters.form_data.gender,
                productType:this.routeParams.product_type,
                smoker:this.m_tobacco_used,
                zip:parameters.form_data.zip,
                dob:parameters.form_data.dob,
                coverage_amount: this.m_coverage_amount,
                term_length: this.m_term_length,
                firstName: parameters.form_data.firstName,
                lastName: parameters.form_data.lastName,
                email: parameters.form_data.email,
                state_id: parameters.form_data.state_id,
                city: parameters.form_data.city,
                age: parameters.form_data.age,
                entry_point: process.env.VUE_APP_LEADENTRYPOINT,
                phone: parameters.form_data.phone,
                zoho_id: this.zoho_id,
                site:parameters.site,
                leadType:parameters.leadType,
                agentInfo:this.agentInfo,
                mode:'edit',
                referrer: (this.routeParams.site === 'OC' && this.routeParams.oc_redirect_url) ? this.routeParams.oc_redirect_url : (this.$cookies.get('set_referring_url')) ? this.$cookies.get('set_referring_url') : document.referrer ? document.referrer : window.location.href,
                utm_source:this.$cookies.get('utm_source'),
                utm_medium: this.$cookies.get('utm_medium'),
                utm_campaign: this.$cookies.get('utm_campaign'),
                utm_token: this.$cookies.get('utm_token'),
                utm_content: this.$cookies.get('utm_content'),
                gclid: this.$cookies.get('gclid'),
                isAnonymous: this.anonymous_leads,
                division: this.division,
            }

            // console.log(postParam);

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.post(ste_url, postParam)
                    .then(res => {
                        this.loading = false;
                        this.btnDisabled = false;
                        this.loading = false;
                        this.show = false;
                        if(res.data.success === true ) {
                            this.loading = false;
                            this.btnDisabled = false;
                            let quoteParams = {};

                            quoteParams['quotes'] = res.data.quotes;
                            quoteParams['form_data'] = postParam;
                            quoteParams['zoho'] = res.data.lead;
                            quoteParams['site'] = parameters.site;
                            quoteParams['site'] = parameters.site;
                            quoteParams['agentInfo'] = this.agentInfo;
                            quoteParams['leadType'] = parameters.leadType;

                            let pathName = (this.currentPathName === 'multiple-quotes') ? 'multiple-quotes-edited' : 'multiple-quotes';
                            this.assignValuesMethod(postParam,res.data.quotes);
                            this.$router.push({name: pathName, params: quoteParams});
                        }
                    })
                    .catch(err => {
                        this.loading = false;
                        this.btnDisabled = false;
                        console.log(err)
                    });

        },
        OpenDialog() {
            this.show = true;
        }
    }
}
</script>

<style scoped>
    .editQuoteDialogue {
        font-family: Montserrat !important;
    }

    .input-control-text {
        font-family: "Montserrat", Sans-serif !important;
    }
</style>
