import axios from "axios";

export default {
    data:() => ({
        chatClick: false,
        agentInfo: {},
    }),
    methods: {
        updateDisposition(lead_id, disposition, redirect_url, new_window) {
            // const parameters = this.$route.params;
            const api_url = process.env.VUE_APP_PROXY + 'update-disposition';
            const param = {
                zoho_id: this.zoho_id,
                disposition: disposition
            }

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            return axios.post(api_url, param).then(res => {
                this.redirecting = false;
                if(res.data.success === true && redirect_url!=null) {

                    if(new_window){
                        window.open(redirect_url, "_blank")
                    }
                    else{
                        window.location.href = redirect_url;
                    }
                }else {
                    return res.data;
                }
            }).catch(error => {
                this.redirecting = false;
                console.log(error);
            });
        },

        updateLeadProductPremium(form_data, lead_info, application) {
            const api_url = process.env.VUE_APP_PROXY + 'update-lead-premium';
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            const post_param = {};
            post_param['form_data'] = form_data;
            post_param['lead_info'] = lead_info;
            post_param['application'] = application;
            return axios.post(api_url, post_param).then(res => {
                return res.data;
            }).catch(error => {
                return error;
            });
        },
        openZohoChat() {
            window.$zoho.salesiq.chat.start();
            window.$zoho.salesiq.floatwindow.visible("show");
        },
        getAgentInfo(site) {
            let div = 'Quility'

            if(window.xprops && window.xprops.Division) {
                div = window.xprops.Division
            }

            const ste_url = process.env.VUE_APP_PROXY + 'get-agent-detail';
            const parameters = this.$route.params;

            if(site === 'QUILITY') {
                this.agentInfo = this.getDefaultQuilityAgent();
                return this.agentInfo;
            }

            if(parameters.agent_code === process.env.VUE_APP_OCAGENTCODE) {
                return this.getDefaultOCAgent(parameters.agent_code);
            }

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

            return axios.post(ste_url, {
                'agent_code':parameters.agent_code,
                'division': div
            }).then(response => {
                const result = response.data.data;
                // console.log('agent code', response.data.data);
                if(typeof result == 'undefined' || result.AgentName === '') {
                    return this.getDefaultOCAgent(parameters.agent_code);
                }
                return {
                    'agentName': result.AgentName,
                    'agentFirstName': result.FirstName,
                    'agentLastName': result.LastName,
                    'agencyName': this.agencyName,
                    'agentPhone': result.AgentPhone,
                    'agentEmail': result.AgentEmail,
                    'agentPayrollID': result.JHWritingNumber,
                    'AgentID': result.AgentID,
                    'agentAddress': result.AddressLine1,
                    'agentCity': result.City,
                    'agentState': result.State,
                    'agentZip': result.Zip,
                    'agentCode': result.AgentCode,
                    'agentLicenses': result.licensedStates,
                    'amam_writingNumbers': result.amam,
                    'Signature': result.Signature,
                    'ExtUID': result.ExtUID,
                    'AffiliateCode': result.AffiliateCode,
                }
            }).catch(error => {
                console.log(error);
                this.agentInfo = this.getDefaultOCAgent(parameters.agent_code);
                return this.agentInfo;
            })
        },

        getDefaultQuilityAgent() {
            return {
                'agentName': process.env.VUE_APP_QAGENTNAME,
                'agencyName': process.env.VUE_APP_QAGENCYNAME,
                'agentPhone': process.env.VUE_APP_QAGENTPHONE,
                'agentBPhone': process.env.VUE_APP_QAGENTBPHONE,
                'agentEmail': process.env.VUE_APP_QAGENTEMAIL,
                'agentPayrollID': process.env.VUE_APP_QAGENTPAYROLL,
                'agentGovtId': process.env.VUE_APP_QAGENTID,
                'agentAddress': process.env.VUE_APP_QAGENTADDRESS,
                'agentCity': process.env.VUE_APP_QAGENTCITY,
                'agentState': process.env.VUE_APP_QAGENTSTATE,
                'agentZip': process.env.VUE_APP_QAGENTZIP,
                'agentCode': process.env.VUE_APP_QAGENTCODE,
                'agentFirstName': process.env.VUE_APP_QAGENTFIRSTNAME,
                'agentLastName': process.env.VUE_APP_QAGENTLASTNAME,
                'amam_writingNumbers': null,
                'Signature': null,
                'agentLicenses': ['CA','AK','AL','AR','AZ','CO','CT','DC',
                    'DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA',
                    'MD','ME','MI','MN','MO','MS','MT','NC','ND','NE','NH','NJ','NM',
                    'NV','NY','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT',
                    'VA','VT','WA','WI','WV','WY']
            }
        },

        getDefaultOCAgent() {
            return {
                'agentName': process.env.VUE_APP_OCAGENTNAME,
                'agencyName': process.env.VUE_APP_OCAGENCYNAME,
                'agentPhone': process.env.VUE_APP_OCAGENTPHONE,
                'agentBPhone': process.env.VUE_APP_OCAGENTBPHONE,
                'agentEmail': process.env.VUE_APP_OCAGENTEMAIL,
                'agentPayrollID': process.env.VUE_APP_OCAGENTPAYROLL,
                'AgentID': process.env.VUE_APP_OCAGENTID,
                'agentAddress': process.env.VUE_APP_OCAGENTADDRESS,
                'agentCity': process.env.VUE_APP_OCAGENTCITY,
                'agentState': process.env.VUE_APP_OCAGENTSTATE,
                'agentZip': process.env.VUE_APP_OCAGENTZIP,
                'agentCode': process.env.VUE_APP_OCAGENTCODE,
                'agentFirstName': process.env.VUE_APP_OCAGENTFIRSTNAME,
                'agentLastName': process.env.VUE_APP_OCAGENTLASTNAME,
                'amam_writingNumbers': {
                    'Occidental': '0000079768',
                    'amam':'0000105370'
                },
                'Signature': null,
                'agentLicenses': ['NC','AK','AL','AR','AZ','CA','CO','CT','DC',
                    'DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA',
                    'MD','ME','MI','MN','MO','MS','MT','ND','NE','NH','NJ','NM',
                    'NV','NY','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
                    'VA','VT','WA','WI','WV','WY']
            }
        },

        logEvent(category, label, action) {
            if (typeof gtag == 'function') {
                gtag('event', action, {'event_category': category,'event_label': label,}); // eslint-disable-line
            }
        },

        logFBConversion(category, label, action) {
            try {
                if (typeof fbq == 'function') {
                    fbq('track', action, {'content_category': category,'content_type': label,}); // eslint-disable-line
                }
            } catch (err) {
                console.log("whooppsie fb : " + err.message);
            }
        },

        logBingConversion(category, label, action) {
            try {
                window.uetq = window.uetq || [];
                if (typeof window.uetq == 'object') {
                    action = action.replace(' ', '_');
                    label = label.replace(' ', '_');
                    category = category.replace(' ', '_');
                    window.uetq.push('event', action, {
                        'event_category': category,
                        'event_label': label,
                        'event_value': 1
                    });
                }
            } catch (err) {
                console.log("whooppsie bing: " + err.message);
            }
        },

        /**
        * Validates hex value
        * @param  {String} color hex color value
        * @return {Boolean} true|false
        */
        isValidHex(color) {
            if(!color || typeof color !== 'string') return false;

            // Validate hex values
            if(color.substring(0, 1) === '#') color = color.substring(1);

            switch(color.length) {
            case 3: return /^[0-9A-F]{3}$/i.test(color);
            case 6: return /^[0-9A-F]{6}$/i.test(color);
            case 8: return /^[0-9A-F]{8}$/i.test(color);
            default: return false;
            }
        },
    },
    computed: {
        sbli_redirect(){
            return this.$store.state.aff_redirect_url
        },

        error_url(){
            if(!window.xprops) {
                return false
            }

            let errorUrl = null

            if(window.xprops && window.xprops.AppErrorURL) {
                errorUrl = window.xprops.AppErrorURL
            } else if(window.xprops && window.xprops.ThankYouUrl) {
                errorUrl = window.xprops.ThankYouUrl
            }

            return errorUrl
        },

        open_app_in_new_page: function() {
            return window.xprops && window.xprops.OpenAppInNewPage
        },

        use_affiliate_code() {
            return window.xprops && window.xprops.UseAffiliateCode
        },
    }
};