<template>
    <v-dialog width="580" v-model="show">
        <v-card>
            <v-card-title class="dialog-header-text" style="font-family: FreightDispProBlack-Regular">
                Oops!
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click.stop="show=false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-5 error-message" >
                <p>
                    We are experiencing technical issues with our online application.
                </p>
                <p>
                    A member of our team will be in touch with the information you provided to help you get the coverage you need.
                </p>
                <p>
                    If you need immediate assistance please reach out to <a href="mailto:help@quility.com" style="color:#46C3B2;">help@quility.com</a>.
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "QuoteErrorDialog",
    props:['visible'],
    computed: {
        show: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                if(!value) {
                    this.$emit('input', value);
                }
            }
        }
    }
}
</script>

<style scoped>
    .error-message p {
        font-family: Montserrat, Light !important;
        font-size: 1.2em;
    }
</style>