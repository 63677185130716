import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aff_redirect_url: null
  },
  mutations: {
    updateSBLIRedirect(state, data) {
        state.aff_redirect_url = data.site
    },
  },
  actions: {
  },
  modules: {
  }
})
