<template>
    <div>
        <v-form ref="form" v-model="valid">
            <v-card tile>
                <v-card-title v-if="!noHeader" :style="computed_coverage_title_container">
                    <v-row>
                        <v-col class="col-md-4 mt-md-2 pr-xs-0 get-a-quote-block pt-xl-1  "  >Coverage for as low as</v-col>
                        <v-col class="col-md-5 pt-md-2 pt-xl-0 " >
                            <div class="mt-md-6 mt-xs-8 price-teaser" style="border-bottom: 1px solid white;"><strong>$</strong></div>
                        </v-col>
                        <v-col class="mt-md-7 pl-md-0 col-md-3 pt-xl-0 pt-md-2 price-teaser-period " >&nbsp;/
                            Monthly<sup>*</sup></v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="pa-6">
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="Date of Birth" placeholder="MM/DD/YYYY"
                                        outlined
                                        v-model="dob"
                                        :rules="[rules.required, rules.dob, rules.age_under18, max_age]"
                                        hide-details="auto"
                                        append-outer-icon="mdi-spacer"
                                        dense
                                        v-mask="'##/##/####'"
                                        class="input-control-text"
                                        id="dob"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-select
                                id="coverage_amnt-mp_tl"
                                label="Coverage Amount"
                                outlined
                                v-model="coverage_amount"
                                hide-details="auto"
                                :items="computed_coverage_items"
                                :rules="[rules.required]"
                                dense
                                ref="dd_coverage_amount"
                                class="input-control-text">
                                <template v-slot:append-outer >
                                    <Cov_amt_help_dialog :agent-data="dialogData"></Cov_amt_help_dialog>
                                </template>
                            </v-select>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-select
                                dense
                                label="Term Length"
                                outlined
                                v-model="coverage_plan"
                                hide-details="auto"
                                :items="plan_items"
                                class="dropdown-style input-control-text"
                                ref="dd_term_length"
                                append-outer-icon="mdi-help-circle-outline"
                                id="coverage_plan"
                                :rules="[term_age_limits]"
                            >
                                <template v-slot:append-outer>
                                    <Term_length_help_dialog :agent-data="dialogData"></Term_length_help_dialog>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-select label="Gender at birth" :items="['Male', 'Female']" v-model="gender" dense
                                    :rules="[rules.required]" outlined hide-details="auto"
                                    class="input-control-text"
                                    ref="dd_gender"
                                    id="gender"
                            >
                                <template v-slot:append-outer>
                                    <Gender_help_dialog></Gender_help_dialog>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-select label="Tobacco used in last 12 months" :items="['Yes', 'No']" dense
                                    v-model="tobacco_used"
                                    :rules="[rules.required]" outlined hide-details="auto"
                                    class="input-control-text"
                                    ref="dd_tobacco"
                                    id="tobacco_used"
                            >
                                <template v-slot:append-outer>
                                    <Tobacco_help_dialog></Tobacco_help_dialog>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="Zip Code"
                                        outlined
                                        :rules="[rules.required, zip_code_validate, available_state]"
                                        v-model="zipCode"
                                        ref="fld_zipCode"
                                        hide-details="auto" class="input-control-text"
                                        dense
                                        append-outer-icon="mdi-spacer"
                                        id="zip"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="!anonymous_leads">
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="First Name"
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="firstName"
                                        hide-details="auto" class="input-control-text"
                                        dense
                                        append-outer-icon="mdi-spacer"
                                        id="first_name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="!anonymous_leads">
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="Last Name"
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="lastName"
                                        hide-details="auto" class="input-control-text"
                                        dense
                                        append-outer-icon="mdi-spacer"
                                        id="last_name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="!anonymous_leads">
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="Email Address"
                                        outlined
                                        :rules="[rules.required, rules.email]"
                                        v-model="email"
                                        hide-details="auto" class="input-control-text"
                                        append-outer-icon="mdi-spacer"
                                        dense
                                        height="20"
                                        id="email"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="!anonymous_leads">
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="Phone Number"
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="phoneNumber"
                                        hide-details="auto" class="input-control-text"
                                        dense
                                        append-outer-icon="mdi-spacer"
                                        placeholder="(###)-###-####"
                                        v-mask="'(###)-###-####'"
                                        id="phone"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="doShowSpanishOptionCheckbox">
                        <v-col cols="12" sm="6" md="12" style="font-family: Montserrat; font-weight:300; font-size:20px;">
                            <v-checkbox v-model="Spanish_Speaking" color="#6ecf93" label="Necesito hablar con un representante en Español." />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="11" sm="6" md="11">
                            <div class="pl-md-6">
                                <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified"
                                            @expired="onCaptchaExpired"
                                            sitekey="6Lfro74bAAAAAKheR_A763Jz15C2GxY6xigtBik9"  ></vue-recaptcha>
                            </div>

                            <v-card-text class="row" v-if="btn_captcha" style="font-family: Montserrat">Please verify you are human</v-card-text>
                            <v-btn
                                class="input-control-text mt-4"
                                style="text-transform: none;"
                                type="button"
                                @click="requestQuote"
                                large
                                block
                                depressed
                                dark
                                append-outer-icon="mdi-spacer"
                                id="btnRAQ"
                                :color="form_color"
                            >
                                Calculate My Quote

                            </v-btn>
                        </v-col>
                        <v-col v-if="quote_error">
                            <div style="font:italic normal 300 11px/16px Montserrat; color:#FF0000">
                                {{quote_error_message}}
                            </div>
                        </v-col>
                    </v-row>
                    <WidgetFooter></WidgetFooter>
                </v-card-text>
            </v-card>
        </v-form>
        <v-dialog
            v-model="dialog"
            persistent
            width="300"
            style="font-family: montserrat, Sans-serif"
        >
            <v-card :color="form_color" dark>
                <v-card-text v-if="quote_error"  style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;" class="pa-3">
                    <span>An error was encountered, if a new window does not open, then <a :href=error_url target="_blank" style="color: inherit;">click here</a> for more info.</span>
                </v-card-text>
                <v-card-text v-else style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;" class="pa-3">
                    Generating Quote
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-4"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <SubscriptionDialog v-model="subscription_dialog" :zoho-id="zoho_id"></SubscriptionDialog>
    </div>
</template>

<script>

let moment = require('moment');

import numeral from "numeral";
import axios from "axios";
import SubscriptionDialog from "@/components/SubscriptionDialog";
import quilityMixin from "@/mixins/quilityMixin";
import Tobacco_help_dialog from "@/components/tobacco_help_dialog";
import Gender_help_dialog from "@/components/gender_help_dialog";
import Term_length_help_dialog from "@/components/term_length_help_dialog";
import Cov_amt_help_dialog from "@/components/cov_amt_help_dialog";
import VueRecaptcha from 'vue-recaptcha'
import WidgetFooter from "@/components/WidgetFooter.vue";

export default {
    name: "RAQForm",
    components: {
        Cov_amt_help_dialog,
        Term_length_help_dialog, Gender_help_dialog, Tobacco_help_dialog, SubscriptionDialog,
        VueRecaptcha, WidgetFooter
    },
    mixins:[quilityMixin],
    props: {
        plan_lists: {
            type: Object,
            default: function () {
                const terms = [
                    {text: '10 Years', value: '10 Years'},
                    {text: '15 Years', value: '15 Years'},
                    {text: '20 Years', value: '20 Years'},
                    {text: '30 Years', value: '30 Years'},
                ];
                return {terms};
            }
        },
        amam_only_states: {
            type: Array,
            default: function() {
                return ['FL', 'ND'];
            }
        },
        compData: {
            type:Object
        }
    },
    data: () => ({
        subscription_dialog: false,
        coverage_amt_dialog: false,
        term_length_dialog: false,
        gender_dialog: false,
        tobacco_dialog: false,
        valid: false,
        dob: null,
        plan_items: [],
        coverage_amount: null,
        coverage_plan: null,
        gender: null,
        age: null,
        tobacco_used: null,
        zipCode: null,
        lastName: null,
        firstName: null,
        email: null,
        phoneNumber: null,
        dateFormat: 'MM/DD/YYYY',
        coverage_limit: 40,
        coverage_items: [],
        fld_zipCode: 'fld_zipCode',
        Spanish_Speaking: false,
        state_id: null,
        city: null,
        zipError: null,
        zoho_id: null,
        quote_error: false,
        quote_error_message: null,
        rules: {
            required: value => !!value || 'Required.',
            dob: v => (v!=null && moment(v.toString(), 'MM/DD/YYYY').isValid()) || 'Enter a valid birth date',
            age_under18: v => moment().diff(v, 'years') > 18 || 'Age must be greater than 18',
            // age_over75: v => moment().diff(v, 'years') < 75 || 'Age must be less than 75',
            phone: v => (Number.isInteger(Number(v)) && v.length == 10) || 'Please enter a valid phone number in the phone field.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                // const pattern = '';
                return pattern.test(value) || 'Invalid e-mail.'
            },
        },
        select: [],
        dialog: false,
        api_url: process.env.VUE_APP_PROXY,
        captcha_verified: false,
        btn_captcha: false,
        agentInfo: {},
        dialogData: null,
        leadType:'Quility',
    }),
    computed: {
        term_length_validate: function () {
            if (this.amam_only_states.includes(this.state_id)) {
                return value => (value === '15 Years') || 'This value is not supported for the state you selected';
            }
            return value => !!value || 'Required.'
        },
        coverage_amount_validate: function () {
            if (this.amam_only_states.includes(this.state_id)) {
                return value => (value > 300000) || 'This value is not supported for the state you selected';
            }

            return value => !!value || 'Required.'
        },
        zip_code_validate: function() {
            if(this.zipError === 'error') {
                return false || 'Zip code does not exist';
            }
            return v => Number.isInteger(Number(v)) && Number(v) < 99999 && (v !== null && v.length == 5) || 'Enter a valid zipcode'
        },
        form_color: function() {
            if(!window.xprops) {
                return 'black'
            }

            let formColor = 'black'

            if(window.xprops && window.xprops.FormColor) {
                if(this.isValidHex(window.xprops.FormColor)) {
                    formColor = window.xprops.FormColor
                }
            }

            return formColor
        },
        computed_coverage_title_container: function() {
            return {
                    "font-size": "0.9rem",
                    "line-height": "1.2rem",
                    "color": "white",
                    "background": this.form_color,
                    "font-family": "montserrat, Sans-serif",
            }
        },
        anonymous_leads: function() {
            if(!window.xprops) {
                return false
            }

            let anonLeads = false

            if(window.xprops && window.xprops.AnonymousLeads) {
                anonLeads = window.xprops.AnonymousLeads
            }

            return anonLeads
        },
        redirect_url: function() {
            return this.$route.query.redirect_url ? this.$route.query.redirect_url : window.location.href
        },
        term_age_limits: function() {
            if(!this.coverage_plan){
                return true
            }

            let max = -1
            let min = -1

            const age = this.insuranceAge(this.dob)

            switch (this.coverage_plan) {
                case '10 Years':
                case '15 Years':
                case '20 Years':
                    max = 60
                    min = 18
                    break
                case '30 Years':
                    max = 50
                    min = 18
                    break
                default:
                    break;
            }

            return (age >= min && age <= max) ? true : `${max} and ${min} are the maximum and minimum age range for the term length of ${this.coverage_plan}.`
        },
        computed_coverage_items: function() {
            if (!this.dob) {
                return this.age_based_coverage_amounts(this.coverage_amount)
            }

            const age = this.insuranceAge(this.dob)
            return this.age_based_coverage_amounts(age)
        },
        computed_age: function() {
            return this.dob ? this.insuranceAge(this.dob) : null
        },
        available_state: function() {
            // return ['NY','MT'].includes(this.state_id) ? 'Not available in NY & MT' : true
            // return (this.agentInfo.agentLicenses.includes(this.state_id) && !['NY','MT'].includes(this.state_id)) ? true : `Not available in ${this.state_id}`

            if(this.state_id && this.agentInfo && this.agentInfo.agentLicenses)
            {
                return (this.agentInfo.agentLicenses.includes(this.state_id) && !['NY','MT'].includes(this.state_id)) ? true : `Not available in ${this.state_id}`
            }

            return true
            // return false
        },
        max_age: function() {
            return this.computed_age > 85 ? 'Maximum age limit is 85.' : true
        },
        doShowSpanishOptionCheckbox() {
            if (!window.xprops) { return false }

            return Boolean(window.xprops.ShowSpanishOption)
        },
        testEmail() {
            if(!window.xprops) {
                return false
            }

            let testemail = null

            if(window.xprops && window.xprops.TestEmail) {
                testemail = window.xprops.TestEmail

                if(testemail === false) {
                    testemail = null
                }
            }

            return testemail
        },
        noHeader: function() {
            if(!window.xprops) {
                return false
            }

            let noHeader = false

            if(window.xprops && window.xprops.Headerless) {
                noHeader = window.xprops.Headerless
            }

            return noHeader
        },
        division: function() {
            if(!window.xprops) {
                return false
            }

            let div = 'Quility'

            if(window.xprops && window.xprops.Division) {
                div = window.xprops.Division
            }

            return div
        },
    },
    watch: {
        zipCode: function (value) {
            if (value.length === 5) {
                this.getStateByZipCode(value);
            }
        },
        compData: function(newValue) {
            this.agentInfo = newValue.agentInfo;
            this.dialogData = {
                'site' : this.compData?.site,
                'agentPhone': this.compData?.agentInfo?.agentPhone
            }
        },
        coverage_limit: function(newValue) {
            this.coverage_items = this.setCoverageItems(newValue);
        },
        dob: function(newValue) {
            if(newValue.length < 10) {
                return false;
            }

            this.age = this.insuranceAge(newValue)

            // reset coverage amounts
            let dist = Number.MAX_VALUE
            let newIndex = -1

            const computedAmounts = this.computed_coverage_items

            if(!computedAmounts.includes(this.coverage_amount)){
                for (const index in computedAmounts) {
                    let curDist = Math.abs(computedAmounts[index].value - this.coverage_amount)
                    if(curDist < dist){
                        dist = curDist
                        newIndex = index
                    }
                }

                if(this.coverage_amount && dist !== Number.MAX_VALUE) {
                    this.coverage_amount = computedAmounts[newIndex].value
                }
            }
        }
    },
    methods:{
        setCoverageItems(limit) {
            let r = []
            for (let i = 0; i < limit; i++) {

                let amt = (25000 + (i * 25000));
                r.push({
                    'text': this.formatCurrency(amt),
                    'value': amt
                })
            }
            r.reverse();
            return r;
        },
        formatCurrency(a) {
            return numeral(a).format('$ 0,0[.]00');
        },
        getStateByZipCode(zipcode) {
            // let ste_url = process.env.VUE_APP_QUILITY_URL + '/wp-admin/admin-ajax.php';
            let ste_url = this.api_url + 'zip-code-quility';

            const postParam = {'zipcode':zipcode};

            axios.post(ste_url, postParam).then(result => {
                this.zipError = '';
                if(result.data.status === 'error') {
                    this.zipError = 'error';
                    return false;
                }

                this.state_id = result.data.zip_info.state_id;
                this.city = result.data.zip_info.city;
            }).catch(err => {
                console.log(err);
            })
        },
        requestQuote() {
            let product_type = this.$route.query.p;
            let ste_url = this.api_url + 'get-quote';
            if (this.$refs.form.validate() === false) {
                return false;
            }

            if(this.captcha_verified === false) {
                // this.$refs.recaptcha.execute();
                this.$refs.recaptcha.reset();
                this.btn_captcha = true;
                return false;
            }

            this.dialog = true;
            product_type = 'Term Life';

            let postParam = {
                gender: this.gender,
                productType: product_type,
                smoker: this.tobacco_used,
                zip: this.zipCode,
                dob: moment(this.dob).format('YYYY-MM-DD'),
                coverage_amount: this.coverage_amount,
                term_length: this.coverage_plan,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phone: this.phoneNumber,
                state_id: this.state_id,
                city: this.city,
                age: this.age,
                entry_point: process.env.VUE_APP_LEADENTRYPOINT,
                mode: 'create',
                site:this.compData.site,
                zoho_id: null,
                agentInfo:this.compData.agentInfo,
                leadType:this.leadType,
                referrer: (this.compData.site === 'OC') ? this.redirect_url : (this.$cookies.get('set_referring_url')) ? this.$cookies.get('set_referring_url') : document.referrer ? document.referrer : window.location.href,
                utm_source:this.$cookies.get('utm_source'),
                utm_medium: this.$cookies.get('utm_medium'),
                utm_campaign: this.$cookies.get('utm_campaign'),
                utm_token: this.$cookies.get('utm_token'),
                utm_content: this.$cookies.get('utm_content'),
                gclid: this.$cookies.get('gclid'),
                isAnonymous: this.anonymous_leads,
                testEmail: this.testEmail,
                division: this.division,
            }

            this.logEvent(product_type, 'RAQ_Complete', 'Created Lead')
            this.logBingConversion(product_type, 'RAQ_Complete', 'Created Lead');
            this.logFBConversion(product_type, 'RAQ_Complete', 'Created Lead');

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.post(ste_url, postParam)
                .then(res => {
                    this.dialog = false;
                    if(res.data.errors) {
                        this.zoho_id = res.data.lead.ZohoID;
                        this.subscription_dialog = true;
                        return false;
                    }
                    if (res.data.success === true) {
                        let quotes = res.data.quotes;
                        if(this.state_id === 'NY') {
                            quotes = quotes.filter(function(item) {
                                return item.Carrier !== 'John Hancock';
                            });
                        }

                        let quoteParams = {};
                        quoteParams['quotes'] = quotes;
                        quoteParams['form_data'] = postParam;
                        quoteParams['zoho'] = res.data.lead;
                        quoteParams['agentInfo'] = this.compData.agentInfo;
                        quoteParams['site'] = this.compData.site;
                        quoteParams['leadType'] = this.leadType;
                        quoteParams['oc_redirect_url'] = this.$route.query.redirect_url;
                        quoteParams['product_type'] = product_type;

                        const query_param = this.$route.query.q;
                        if(typeof query_param !== 'undefined') {
                            if(query_param === 'ab1') {
                                this.$router.push({name: 'TermCoverage', params: quoteParams})
                            } else if(query_param === 'ab2') {
                                this.$router.push({name: 'multiple-quotes', params: quoteParams})
                            }
                        } else {
                            res.data
                            const routeName = (this.compData.site === 'OC') ? 'Symm-quotes' : 'multiple-quotes';
                            this.$router.push({name: routeName, params: quoteParams, query: {p:""}});
                        }
                    }
                    else if(res.data.success === false) {
                        this.dialog = true;
                        this.quote_error = true;
                        this.quote_error_message = 'Error!. Please try again later.';

                        if(this.open_app_in_new_page) {
                            window.open(this.error_url, "_blank")
                        } else {
                            window.location.href = this.error_url
                        }
                    }

                })
                .catch(err => {
                    this.dialog = true;
                    this.quote_error = true;
                    console.log(err)

                    if(this.open_app_in_new_page) {
                        window.open(this.error_url, "_blank")
                    } else {
                        window.location.href = this.error_url
                    }
                });
        },
        insuranceAge: function (original_dob) {
            if (original_dob === '' || original_dob === null) {
                return;
            }
            //this figures out "insurance age" meaning if your next birthday is closer, then they consider you that age.
            const dob = moment(original_dob, 'MM/DD/YYYY');

            const day = dob.format('DD');
            const month = dob.format('MM');
            const this_year = moment().format('YYYY');
            const last_year = moment().subtract(1, 'Y').format('YYYY');
            const next_year = moment().add(1, 'Y').format('YYYY');
            const real_age = moment().diff(dob, 'years');

            //check if their birthday has already past?
            const bday_this_year = moment(this_year + "-" + month + "-" + day, 'MM/DD/YYYY');
            const had_birthday_already = moment().diff(bday_this_year) > 0 ? true : false;

            let current_age_diff;
            let next_age_diff;
            if (had_birthday_already) {
                current_age_diff = Math.abs(moment().diff(moment(this_year + "-" + month + "-" + day, 'MM/DD/YYYY')))
                next_age_diff = Math.abs(moment().diff(moment(next_year + "-" + month + "-" + day, 'MM/DD/YYYY')))
            } else {
                current_age_diff = Math.abs(moment().diff(moment(last_year + "-" + month + "-" + day, 'MM/DD/YYYY')))
                next_age_diff = Math.abs(moment().diff(moment(this_year + "-" + month + "-" + day, 'MM/DD/YYYY')))
            }
            if (current_age_diff > next_age_diff) {
                //return the "next age" as their next birthday is closer.
                return real_age + 1;
            }
            //return the current age, as their last birthday is closer.
            this.age = real_age;
            return real_age
        },
        onCaptchaVerified(recaptchaToken) {

            let ste_url = this.api_url + 'process-recaptcha';

            const post_param = {
                'secret' : 'AIzaSyAUm7S-d1gsVHX3D1c5GWBnu6IzN_dKcYg',
                'captcha_token': recaptchaToken
            }

            axios.post(ste_url, post_param).then(response => {
                if(response.data.data.valid === true) {
                    this.status = null;
                    this.captcha_verified = true;
                }
                else if(response.data.data.valid === false) {
                    this.captcha_verified = false;
                    this.$refs.recaptcha.reset();
                }
            }).catch(error => {
                console.log(error);
                this.status = null;
            });
        },
        onCaptchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
        age_based_coverage_amounts() {
            const age = this.dob ? this.insuranceAge(this.dob) : 40 //default to 40? Looks like this is being done elsewhere in the codebase

            let upperLimit = 1000000
            let r = []

            if (age >= 18 && age <= 50) {
                upperLimit = 1000000
            }
            else if(age >= 51 && age <= 55) {
                upperLimit = 500000
            }
            else if(age >= 56) {
                upperLimit = 150000
            }

            // SBLI doesn't return a quote for anything under $100,000
            // NOTE: 100,000 - 25000 = 75000, so the last value added to r is $100,000
            while (upperLimit > 75000) {
                r.push({
                    'text': this.formatCurrency(upperLimit),
                    'value': upperLimit
                })

                upperLimit -= 25000
            }

            return r
        },
    },
    mounted() {
        this.coverage_items = this.setCoverageItems(this.coverage_limit);
        this.reCaptcha = process.env.VUE_APP_RECAPTCHA;
        const inputElement = this.$refs.fld_zipCode.$el.querySelector('input');
        inputElement.maxLength = 5;
        this.plan_items = this.plan_lists.terms;

        // There has to be a better way to do this...
        const dd_coverage_amt = this.$refs.dd_coverage_amount.$refs.input;
        dd_coverage_amt.addEventListener('focus', () => {
            this.$refs.dd_coverage_amount.isMenuActive = true
            // this.coverage_amount = 500000;
        }, true);

        const dd_term_length = this.$refs.dd_term_length.$refs.input;
        dd_term_length.addEventListener('focus', () => {
            this.$refs.dd_term_length.isMenuActive = true
        }, true);

        const dd_gender = this.$refs.dd_gender.$refs.input;
        dd_gender.addEventListener('focus', () => {
            this.$refs.dd_gender.isMenuActive = true
        }, true);

        const dd_tobacco = this.$refs.dd_tobacco.$refs.input;
        dd_tobacco.addEventListener('focus', () => {
            this.$refs.dd_tobacco.isMenuActive = true
        }, true);

        let routePath = this.$route.path;
        routePath = routePath.split('/');
        if(routePath[1] === 'symmetry') {
            this.leadType = 'OC DTC Lead';
        }
    },
}
</script>

<style scoped>
.g-recaptcha {
    transform:scale(4);
    transform-origin:0 0;

}
.get-a-quote-container {
    font-family: montserrat, Sans-serif;
}

.get-a-quote-title {
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    text-align: left;
    line-height: 1em;
    color: #002421;
}

.estimate-msg {
    font-size: 3rem;
    color: #46C3B2;
    font-family: freight-display-pro, Sans-serif !important;
    line-height: 1.3em;
    font-weight: 900;
}

.med-thin-text {
    margin-top: 3%;
    font-size: calc(18px + (35 - 22) * ((100vw - 400px) / (2000 - 400))) !important;
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    line-height: 2.5rem;
    color: #000000;
}

.breaking-border {
    background: #DAF3F0;
    width: 51.58px;
    height: 8.38px;
    margin-top: 2%;
}

.coverage-title-container {
    font-size: 0.9rem;
    line-height: 1.2rem;
    color: white;
    /* background: #46C3B2; */
    background: black;
    font-family: montserrat, Sans-serif
}

.input-control-text {
    font-family: "Montserrat", Sans-serif !important;
}

.price-teaser-period {
    font-size: 0.8rem;
}

.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}

.theme--light.v-btn.v-btn--has-b {
    background-color: #005951;
    color: white;
}

@media only screen and (max-width: 768px) {
    .estimate-msg {
        font-family: freight-display-pro, Sans-serif !important;
        font-size: 2rem;
        line-height: 1.2em;
        margin-bottom:0;

    }

    .get-a-quote-block {
        font-size: 0.8rem;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    .price-teaser {
        margin-top:14%;
    }

    .price-teaser-period {
        margin-top:6%;
        font-size: 0.8rem;
        white-space: nowrap;
        word-break: keep-all;
    }

    input::-webkit-input-placeholder{
        color:red;
    }
    input:-moz-placeholder {
        color:red;
    }
}
</style>
