<template>
    <div>
        <v-container class="my-5">
            <v-layout row>
                <v-flex xs12 md5 lg5 class="pb-10 mt-3">
                    <TermQuoteVertical></TermQuoteVertical>
                </v-flex>
                <v-flex md2 lg2>
                    <div class="align-content-center">
                        <div class="center-breaker hidden-md-and-down">
                            <div class="pa-3 secondary rounded-circle d-inline-block or-circle" >OR</div>
                        </div>
                    </div>
                </v-flex>
                <v-flex xs12 class="hidden-sm-and-up center-breaker-small" >
                    <div class="mb-10 mt-md-3 " style="height:0.5px; border-bottom: 0.5px solid black; ">
                        <v-btn depressed fab color="#46C3B2" style="margin-top: -10%;" class="offset-5"  ><strong class="pa-2" style="color:white;font-family: Montserrat, Bold; font-weight: 900;">OR</strong></v-btn>
                    </div>
                </v-flex>
                <v-flex md5 lg5 >
                    <ScheduleConsultation></ScheduleConsultation>
                </v-flex>
            </v-layout>

        </v-container>
    </div>
</template>


<script>
import TermQuoteVertical from "../components/TermQuoteVertical";
import ScheduleConsultation from "../components/ScheduleConsultation";


export default {
    name: "TermCoverage",
    components: { ScheduleConsultation, TermQuoteVertical},
    mounted() {
        // TermQuoteVertical.props.params = this.$route.params;
        window.scrollTo(0,0);
    },
    created() {
        const parameters = this.$route.params;

        if(typeof parameters.form_data === 'undefined') {
            this.$router.push({name: 'GetAQuote'});
        }
    },
}
</script>

<style scoped>
.center-breaker {
    height: 30rem;
    width: 0.5px;
    background: black;
    margin-left: 60%
}

v-btn strong {
    font-family: Montserrat;font-size: 1rem;
    color:white;
}

.or-circle {
    background: #46C3B2;
    font-weight: 900;
    color:white;margin-left: -4500% !important;margin-top: 40000%;font-family: FreightDispProBlack-Regular
}

@media only screen and (max-width: 768px) {
    .center-breaker-small {
        margin-top: 10%;
        margin-bottom: 2%;
    }
}


</style>
