<template>
    <multiple-quotations></multiple-quotations>
</template>

<script>
import MultipleQuotations from "../components/MultipleQuotations";

export default {
    name: "MultipleQuotation",
    components:{
            MultipleQuotations
    },
    mounted() {
        window.scrollTo(0,0);
    }
}
</script>

<style scoped>

</style>
