<template>
    <p class="chat-disclosure">
      This Chat may be recorded or monitored. For details on how we use your data (including this Chat and any information we obtain from this Chat) please
        visit <a style="color: #46C3B2" href="https://quility.com/privacy-policy" >https://quility.com/privacy-policy</a> (our “Privacy Policy”). Personal information about you may collected to better provide services. Any information you provide in this Chat may be used consistent with our Privacy Policy.”
    </p>
</template>

<script>
export default {
  name: "ChatDisclaimerDialog",
  data:() => ({
    chat_dialog:true
  }),
}
</script>

<style scoped>
.chat-disclosure {
  font-family: Montserrat !important;
}
</style>