<template>
    <v-dialog
            width="500"
            v-model="term_length_dialog"
    >
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" tabindex="-1"  :color="form_color">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="dialog-header-text">
                Term Length
                <v-spacer></v-spacer>
                <v-icon large :color="form_color" @click="term_length_dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="mt-0 ">
                <v-divider></v-divider>
                <div class="mt-2 dialog-text">
                    Your "term" is how long your policy will be in force. For
                    mortgage protection insurance, you may consider matching the
                    term of your mortgage.
                </div>
                <div class="mt-3">
                    <h3 class="dialog-context-title">
                        Have questions?</h3>
                    <div style="font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;">
                        Get in touch with us today at <a :href="'tel:'+phone" :style="computed_color_style" id="TLDialogTelNo">{{phone}}</a>
                        <!-- <span style="color: #46C3B2;text-decoration: underline; cursor: pointer;" id="TLDialogChatRef" @click="openZChat">Chat with a licensed agent</span> -->
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "term_length_help_dialog",
    mixins: [quilityMixin],
    props:{
        agentData: {
            type:Object
        }
    },
    data: () => ({
        term_length_dialog: false,
        phone:null,
        agentPhone: '(888) 784-5489',
        site: null
    }),
    methods:{
        openZChat() {
            this.openZohoChat();
        }
    },
    updated() {
        this.site = this.agentData?.site;
        this.phone = (this.agentData?.site == 'OC') ? this.agentData?.agentPhone : this.agentPhone;
    },
    computed: {
        form_color: function() {
            if(!window.xprops) {
                return 'black'
            }

            let formColor = 'black'

            if(window.xprops && window.xprops.FormColor) {
                if(this.isValidHex(window.xprops.FormColor)) {
                    formColor = window.xprops.FormColor
                }
            }

            return formColor
        },
        computed_color_style: function() {
            return {
                'color': this.form_color
            }
        }
    }
}
</script>

<style scoped>
    .dialog-text {
        font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
    }
</style>
